
import { Component, Vue } from "vue-property-decorator";
@Component({
  components: {
    //
  },
})
export default class PartnerCreate extends Vue {
  public el = 1;
}
